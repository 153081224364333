@import "../../assets/scss/bundle.scss";

.form {
  margin: 30px 0 50px;
  .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 25px;
  }
  .address-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    h2 {
      width: 160px;
      @include Typography($font-size-1, 500, $color-gray-900);
    }
    .icon {
      width: 16px;
      height: 16px;
    }
    .help-content {
      padding: 8px 12px;
      border-radius: $border-radius;
      background: $color-gray-600;
      @include Typography(10px, 600, $color-white);

      color: $color-white;
    }
  }

  @include Mobile {
    .row {
      flex-direction: column;
      gap: 10px;
    }
  }
}
