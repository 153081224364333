$color-gray-50: #f9fafb;
$color-gray-100: #f2f4f7;
$color-gray-300: #d1d5db;
$color-gray-400: #9ca3af;
$color-gray-500: #6b7280;
$color-gray-600: #303f46;
$color-gray-700: #374151;
$color-gray-900: #111827;

$color-text-primary: #000d1a;

$color-white: white;
