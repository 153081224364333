@import "../../assets/scss/bundle.scss";

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  main {
    width: 100%;
    border-radius: 6px;
    box-sizing: border-box;
    border: 1px solid $color-gray-300;
    padding: 24px;

    @include Tablet {
      padding: 20px;
    }

    @include Mobile {
      padding: 15px;
    }
  }

  @include Tablet {
    gap: 20px;
  }

  @include Mobile {
    padding: 10px;
    gap: 10px;
  }
}
