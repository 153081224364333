@import "../../assets/scss/bundle.scss";

.fishing-cards {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;

  .active {
    outline: 3px solid $color-gray-600;
  }

  @include Mobile {
    width: 100%;
    gap: 15px;
  }
}
