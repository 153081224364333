@import "../../assets/scss/bundle.scss";

.modal {
  position: fixed; /* фиксированное положение */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); /* цвет фона */
  z-index: 1;
  transition: opacity 200ms ease-in; /* анимация перехода */
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ширина модального окна и его отступы от экрана */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

/* свойства для блока, содержащего контент модального окна */
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: 0;
  @include Mobile {
    width: 90vw !important;
  }
}
@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
/* свойства для заголовка модального окна */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid $color-gray-500;
}
.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  @include Typography($font-size-3, 500, $color-gray-700);
}
/* свойства для кнопки "Закрыть" */
.close {
  float: right;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  text-decoration: none;
}
/* свойства для кнопки "Закрыть" при нахождении её в фокусе или наведении */
.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.75;
}
/* свойства для блока, содержащего основное содержимое окна */
.modal-body {
  text-align: center;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 15px;
  overflow: auto;
  p {
    @include Typography($font-size-3, 500, red);
  }

  .btns-group {
    display: flex;
    justify-content: space-around;
    gap: 30px;

    button {
      width: 150px;
    }
  }
}
