@import "../../assets/scss/bundle.scss";

.payment {
  .agreement {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    svg {
      margin-right: 10px;
    }
    .link {
      color: blue;
      text-decoration: none;
    }
  }
}
