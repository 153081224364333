@import "../../assets/scss/bundle.scss";

.steps {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $color-gray-300;
  border-radius: $border-radius;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @include Mobile {
    flex-direction: column;
  }
}
