@import "../../../assets/scss/bundle.scss";

.fishing-card {
  display: flex;
  width: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 11px;
  overflow: hidden;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  &:hover {
    outline: 3px solid $color-gray-600;
  }
  img {
    height: 190px;
    width: 100%;
  }

  &__title {
    padding: 24px;
    @include Typography($font-size-2, 600, $color-gray-900);
    margin: 0;
  }

  @include Mobile {
    width: 100%;
    img {
      object-fit: cover;
    }
  }
}
