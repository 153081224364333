@import "../../assets/scss/bundle.scss";

.input {
  label {
    @include Typography($font-size-3, 500, $color-gray-700);
    margin-bottom: 9px;
  }
  input {
    display: flex;
    width: 300px;
    padding: 9px 13px;
    @include Typography($font-size-3, 400, $color-gray-500);
    border-radius: 6px;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px $color-gray-300;
  }
  span {
    @include Typography(12px, 400, red);
  }
  .error {
    border: 1px solid red;
    @include Typography($font-size-3, 400, red);
  }

  @include Mobile {
    width: 100%;

    input {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
