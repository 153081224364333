@import "../../../assets/scss/bundle.scss";

.step-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  a {
    text-decoration: none;
  }

  @include Tablet {
    width: 100%;
    .step {
      padding: 10px;
    }
    .step__step {
      width: 30px;
      min-width: 30px;
      height: 30px;
    }
    .step__svg {
      display: none;
    }
  }

  @include Mobile {
    border-bottom: 1px solid $color-gray-300;
    .step {
      padding: 10px 20px;
    }
  }
}

.step-wrap:last-child {
  border-bottom: none;
}

.step {
  display: flex;
  align-items: center;
  padding: 14px;
  &__step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $color-gray-300;
    display: flex;
    justify-content: center;
    align-items: center;
    @include Typography($font-size-3, 500, $color-gray-500);
    margin-right: 16px;
  }
  &__content {
    @include Typography($font-size-3, 500, $color-gray-500);
  }
}
.active .step__step {
  border: 2px solid $color-gray-600;
  @include Typography($font-size-3, 600, $color-gray-600);
}

.doneStep .step__step {
  border: none;
  background: #303f46;
}

.doneStep .step__content,
.active .step__content {
  @include Typography($font-size-3, 600, $color-gray-600);
}
