@import "../../assets/scss/bundle.scss";

.waterGrounds {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid $color-gray-300;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  .active {
    outline: 3px solid $color-gray-600;
  }

  @include Tablet {
    width: 100%;
  }
}
