@import "../../assets/scss/bundle.scss";

.privacy-policy {
  max-width: 1200px;
  margin: 30px auto;

  section {
    margin-bottom: 30px;
  }

  h1 {
    @include Typography($font-size-1, 600, $color-gray-700);
    margin-bottom: 30px;
  }

  h3 {
    @include Typography($font-size-1, 600, $color-gray-700);
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 5px;
  }

  strong {
    color: $color-gray-700;
  }

  a {
    @include Typography($font-size-3, 500, #125fec);
  }

  ul {
    margin-left: 40px;
  }

  li {
    @include Typography($font-size-3, 400, $color-gray-500);
    margin-bottom: 5px;
    &::marker {
      color: $color-gray-700;
    }
  }
}
