/* Font Sizes */
$font-size-1: 1.125rem; // 18px
$font-size-2: 1rem; // 16px
$font-size-3: 0.875rem; // 14px

/* Widths */
$width-desktop: 1400px;
$width-tablet: 900px;
$width-mobile: 560px;

/* Border Radius */
$border-radius: 8px;
